import React, { ReactNode, createContext, useState } from 'react';
import { Pod } from './TreeContainer/VMParser';
import { KeycloakInstance } from 'keycloak-js';

interface DataContextType {
  notifications: string[];
  setNotifications: (data: string[]) => void;
  showDeleteModal: Pod | undefined;
  setShowDeleteModal: (pod: Pod | undefined) => void;
  refreshTree: number;
  setRefreshTree: (num: number) => void;
  viewIndex: number;
  setViewIndex: (num: number) => void;
  showAddModal: number;
  setShowAddModal: (num: number) => void;
  givenName: string;
  setGivenName: (name: string) => void;
  familyName: string;
  setFamilyName: (name: string) => void;
  email: string;
  setEmail: (name: string) => void;
  project: string;
  setProject: (isAuthenticated: string) => void;
}

const defaultData: DataContextType = {
  notifications: [''],
  setNotifications: function (data: string[]): void {
    throw new Error('Function not implemented.');
  },
  showDeleteModal: undefined,
  setShowDeleteModal: function (pod: Pod | undefined): void {
    throw new Error('Function not implemented.');
  },
  refreshTree: 0,
  setRefreshTree: function (num: number): void {
    throw new Error('Function not implemented.');
  },
  viewIndex: 0,
  setViewIndex: function (num: number): void {
    throw new Error('Function not implemented.');
  },
  showAddModal: 0,
  setShowAddModal: function (num: number): void {
    throw new Error('Function not implemented.');
  },
  givenName: '',
  familyName: '',
  email: '',
  setGivenName: function (name: string): void {
    throw new Error('Function not implemented.');
  },
  setFamilyName: function (name: string): void {
    throw new Error('Function not implemented.');
  },
  setEmail: function (name: string): void {
    throw new Error('Function not implemented.');
  },
  project: '',
  setProject: function (isAuthenticated: string): void {
    throw new Error('Function not implemented.');
  },
};

export const DataContext = createContext<DataContextType>(defaultData);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FunctionComponent<DataProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<Pod | undefined>(undefined);
  const [refreshTree, setRefreshTree] = useState<number>(0);
  const [viewIndex, setViewIndex] = React.useState<number>(3); //namespace by default

  const [showAddModal, setShowAddModal] = useState<number>(0);

  const [givenName, setGivenName] = useState<string>('');
  const [familyName, setFamilyName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [project, setProject] = useState<string>('');

  return (
    <DataContext.Provider value={{
      notifications,
      setNotifications,
      showDeleteModal,
      setShowDeleteModal,
      refreshTree,
      setRefreshTree,
      viewIndex,
      setViewIndex,
      showAddModal,
      setShowAddModal,
      givenName,
      setGivenName,
      familyName,
      setFamilyName,
      email,
      setEmail,
      project,
      setProject,
    }}>
      {children}
    </DataContext.Provider>
  )
};