export interface NetworkInterface {
    name: string;
    mac: string;
    interfaceName: string;
    ipAddress: string;
}

export interface Volume {
    name: string;
    type: string;
    mountPoint: string;
}

export interface DataVolume {
    name: string;
}

export interface RamMetrics {
    percent: string;
    total: string;
    used: string;
}

export interface StorageMetrics {
    percent: string;
    total: string;
    used: string;
}

export interface CpuMetrics {
    percent: string;
}

export interface Metrics {
    ram: RamMetrics;
    storage: StorageMetrics;
    cpu: CpuMetrics;
}

export interface Condition {
    type: string;
    status: string;
    lastTransitionTime: string;
    lastProbeTime: string;
    message: string;
    reason: string;
}

export interface Label {
    key: string;
    value: string;
}

export interface Pod {
    name: string;
    ip: string;
    cores: string;
    memory: string;
    nodeName: string;
    vpc: string;
    network: string;
    logical_switch: string;
    instanceType: string;
    status: string;
    phase: string;
    printableStatus: string;
    imageName: string;
    kernel: string;
    volumes: Volume[];
    dataVolumes: DataVolume[];
    networkInterfaces: NetworkInterface[];
    metrics: Metrics;
    username: string;
    conditions: Condition[];
    sshUser: string;
    sshKey: string;
    domain: string;
    labelList: Label[];
    terminationGracePeriodSeconds: number;
    failureThreshold: number;
    initialDelaySeconds: number;
    periodSeconds: number;
    timeoutSeconds: number;
};

export const emptyPod = () => {
    let item: Pod = {
        ip: 'Not Specified',
        cores: 'Not Specified',
        memory: 'Not Specified',
        nodeName: 'Not Specified',
        vpc: '',
        network: 'Not Specified',
        instanceType: 'Not Specified',
        logical_switch: 'Not Specified',
        status: '',
        imageName: 'Not Specified',
        volumes: [{ name: 'Not Specified', type: '', mountPoint: '' }],
        kernel: '',
        networkInterfaces: [{ name: 'Not Specified', mac: '', interfaceName: '', ipAddress: '' }],
        metrics: {
            ram: {
                percent: '0',
                total: 'N',
                used: 'M'
            },
            storage: {
                percent: '0',
                total: 'N',
                used: 'M'
            },
            cpu: {
                percent: '0'
            }
        },
        username: '',
        conditions: [],
        name: '',
        sshUser: 'Not Specified',
        sshKey: 'Not Specified',
        domain: 'Not Specified',
        printableStatus: "Unknown",
        phase: "Unknown",
        labelList: [],
        dataVolumes: [],
        terminationGracePeriodSeconds: 0,
        failureThreshold: 0,
        initialDelaySeconds: 0,
        periodSeconds: 0,
        timeoutSeconds: 0
    };
    return item;
}

export const parseVM = (rootData, skipMetrics) => {
    let data = rootData.vmi;
    let item = emptyPod();

    // Stopped or Running
    item.printableStatus = rootData.vm.status.printableStatus;
    let findPausedCondition = rootData.vm.status.conditions.filter((item) => { return item.reason === "PausedByUser" });
    let isPaused = findPausedCondition.length > 0;
    if (isPaused) {
        item.printableStatus = 'Paused';
    }

    let findProvisioningCondition = rootData.vm.status.conditions.filter((item) => { return item.type === "Provisioning" });
    let isProvisioning = findProvisioningCondition.length > 0;
    if (isProvisioning) {
        item.printableStatus = 'Provisioning';
    }

    if (item.printableStatus === 'Stopped') {
        item.name = rootData.vm.metadata.name;
        item.cores = rootData.vm.spec.template.spec.domain.cpu.cores;
        item.memory = rootData.vm.spec.template.spec.domain.memory.guest;
    }


    item.phase = data.status.phase;

    let conditions: Condition[] = [];
    rootData.vm.status.conditions.forEach(element => {
        let condition: Condition = {
            type: element.type,
            status: element.status,
            lastTransitionTime: element.lastTransitionTime,
            lastProbeTime: element.lastProbeTime,
            message: element.message,
            reason: element.reason
        };
        conditions.push(condition)
    })
    item.conditions = conditions;



    if (data.status.phase === 'Running') {
        item.name = data.metadata.name;
        item.ip = data.status.interfaces[0].ipAddress;
        item.cores = data.spec.domain.cpu.cores;
        item.memory = data.spec.domain.resources.requests.memory;
        item.nodeName = data.status.nodeName;
        item.vpc = data.metadata.annotations['ovn.kubernetes.io/vpc'];
        item.network = data.spec.networks[0].multus.networkName;
        item.logical_switch = data.metadata.annotations['ovn.kubernetes.io/logical_switch'] || 'Not Specified';
        item.instanceType = data.spec.domain.machine.type;
        item.imageName = data.status.launcherContainerImageVersion;
        item.terminationGracePeriodSeconds = data.spec.terminationGracePeriodSeconds;
        item.failureThreshold = data.spec.terminationGracePeriodSeconds;
        item.periodSeconds = data.spec.readinessProbe.periodSeconds;
        item.timeoutSeconds = data.spec.readinessProbe.timeoutSeconds;
        item.initialDelaySeconds = data.spec.readinessProbe.initialDelaySeconds;
        item.failureThreshold = data.spec.readinessProbe.failureThreshold;
        if (data.guestosinfo && data.guestosinfo.os.prettyName) {
            item.imageName = data.guestosinfo.os.prettyName;
            item.kernel = data.guestosinfo.os.kernelRelease;
        }
        item.status = '';

        if (data.filesystemlist) {
            let fss = data.filesystemlist.items.filter((item) => {
                return !item.mountPoint.startsWith('/boot');
            });
            let volumes: Volume[] = [];
            fss.forEach(element => {
                let volume: Volume = { name: element.diskName, type: element.fileSystemType, mountPoint: element.mountPoint };
                volumes.push(volume);
            });
            item.volumes = volumes;
        }

        let dataVolumes: DataVolume[] = [];
        let dvms = data.spec.volumes.filter((item) => { return !!item.dataVolume });
        dvms.forEach(element => {
            let volume: DataVolume = { name: element.dataVolume.name };
            dataVolumes.push(volume);
        });
        item.dataVolumes = dataVolumes;


        let networkInterfaces: NetworkInterface[] = [];
        data.status.interfaces.forEach(element => {
            if (element.infoSource.startsWith('domain')) {
                let networkInterface = { name: element.name, mac: element.mac, interfaceName: element.interfaceName, ipAddress: element.ipAddress }
                networkInterfaces.push(networkInterface);
            }
        })
        item.networkInterfaces = networkInterfaces;

        try {
            item.sshKey = data.spec.accessCredentials[0].sshPublicKey.source.secret.secretName
            item.sshUser = data.spec.accessCredentials[0].sshPublicKey.propagationMethod.qemuGuestAgent.users[0]
            item.domain = data.metadata.name + '.' + data.metadata.namespace;
        } catch (e) {
            console.error('unable to get ssh key or ssh user or domain', data);
        }

        if (
            (data.metrics && data.metrics.ram.usage_percent.data.result.length !== 0 && data.metrics.fs.total.data.result.length !== 0 && data.metrics.cpu.usage_percent.data.result.length !== 0)
            && !skipMetrics
        ) {
            let percent = parseFloat(data.metrics.ram.usage_percent.data.result[0].value[1]).toFixed(2);
            let used = parseFloat(data.metrics.ram.usage_gib.data.result[0].value[1]).toFixed(2);
            let total = parseFloat(data.metrics.ram.total_gib.data.result[0].value[1]).toFixed(2);

            let ram: RamMetrics = {
                percent: percent,
                total: total,
                used: used
            };

            item.metrics.ram = ram;

            let storage_total = parseFloat(data.metrics.fs.total.data.result[0].value[1]).toFixed(2);
            let storage_used = parseFloat(data.metrics.fs.used.data.result[0].value[1]).toFixed(2);
            let storage_percent = (parseFloat(storage_used) / (parseFloat(storage_total) / 100)).toFixed(2);

            let storage: StorageMetrics = {
                percent: storage_percent,
                total: storage_total,
                used: storage_used
            }

            item.metrics.storage = storage;

            var cpu_percent = parseFloat(data.metrics.cpu.usage_percent.data.result[0].value[1]).toFixed(2);
            item.metrics.cpu.percent = cpu_percent;
        }

        try {
            item.username = data.spec.accessCredentials[0].sshPublicKey.propagationMethod.qemuGuestAgent.users[0];
        } catch (exc) {
            console.error(exc);
        }

        let conditions: Condition[] = [];
        rootData.vm.status.conditions.forEach(element => {
            let condition: Condition = {
                type: element.type,
                status: element.status,
                lastTransitionTime: element.lastTransitionTime,
                lastProbeTime: element.lastProbeTime,
                message: element.message,
                reason: element.reason
            };
            conditions.push(condition)
        })
        item.conditions = conditions;

        for (const property in data.metadata.labels) {
            if (property.indexOf('kubevirt') === -1 && property !== 'name') {
                let _label: Label = {
                    key: property,
                    value: data.metadata.labels[property]
                }
                let label = `${_label.key}: ${_label.value}`;
                console.log(label);

                item.labelList.push(_label);
            }
        }

    } else if (data.status.phase === 'Pending') {
        item.name = data.metadata.name;
        item.cores = data.spec.domain.cpu.cores;
        item.memory = data.spec.domain.resources.requests.memory;
        item.nodeName = data.status.nodeName || 'Not Specified';
        item.vpc = data.metadata.annotations['ovn.kubernetes.io/vpc'];
        item.network = data.spec.networks[0].multus.networkName;
        item.logical_switch = data.metadata.annotations['ovn.kubernetes.io/logical_switch'] || 'Not Specified';
        item.instanceType = data.spec.domain.machine.type;
        item.imageName = data.status.launcherContainerImageVersion;
        let errors = rootData.vm.status.conditions.filter(item => { return item.reason === 'Unschedulable' });
        let error_message = '';
        if (errors.length > 0) {
            error_message = errors[0].message;
        }
        item.status = error_message;
    }

    console.log('parseVM: ', item);
    return item;
}