import * as React from 'react';
import {
  ActionList,
  ActionListItem,
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownList,
  MenuToggle,
  MenuToggleElement,
  Tooltip
} from '@patternfly/react-core';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import { DataContext } from '@app/DataContext';
import { useContext } from 'react';
import { Pod } from '@app/TreeContainer/VMParser';

export interface ActionListKebabProps {
  pod?: Pod | undefined,
}
export const ActionListKebab: React.FunctionComponent<ActionListKebabProps> = ({ pod }) => {
  const { showDeleteModal, setShowDeleteModal } = useContext(DataContext);

  const [isOpen, setIsOpen] = React.useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = (event: React.MouseEvent<Element, MouseEvent> | undefined) => {
    event?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const openDeleteModal = () => {
    console.log('delete vm');
    setShowDeleteModal(pod as Pod);
  }

  const dropdownItems = (
    <>
      <Tooltip content={'Delete Virtual Machine'}>
        <DropdownItem onClick={openDeleteModal} key="link">
          Delete
        </DropdownItem>
      </Tooltip>
    </>
  );


  return (
    <React.Fragment>
      <ActionList>
        <ActionListItem>
          <Dropdown
            onSelect={onSelect}
            toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
              <MenuToggle
                ref={toggleRef}
                onClick={onToggle}
                variant="plain"
                isExpanded={isOpen}
                aria-label="Action list single group kebab"
              >
                <EllipsisVIcon />
              </MenuToggle>
            )}
            isOpen={isOpen}
            onOpenChange={(isOpen: boolean) => setIsOpen(isOpen)}
          >
            <DropdownList>{dropdownItems}</DropdownList>
          </Dropdown>
        </ActionListItem>
      </ActionList>
    </React.Fragment>
  );
};