import * as React from 'react';
import '@patternfly/react-core/dist/styles/base.css';
import 'highlight.js/styles/default.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLayout } from '@app/AppLayout/AppLayout';
import { AppRoutes } from '@app/routes';
import '@app/TreeContainer/VirtualMachinesView.css';
import '@app/circles.css';
import '@app/app.css';
import { DataProvider } from './DataContext';

import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { User, WebStorageStateStore } from 'oidc-client-ts';



const App: React.FunctionComponent = () => {




  const getConfig = () => {
    console.log('This is config')
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const realm = urlParams.get('realm') || localStorage.getItem('organization');;
    console.log('realm', realm);

    let keycloakURL = (window as any).keycloakURL || 'https://keycloak.stage.kube-dc.com';

    let config = {
      authority: `${keycloakURL}/realms/kube-dc`,
      client_id: "kube-dc",
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      onSigninCallback: (_user: User | void): void => {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        )
      }
    };

    if (realm) {
      config.authority = `${keycloakURL}/realms/${realm}`;
    }

    console.log('config', config);
    return config;
  }



  return (
    <AuthProvider {...getConfig()}>
      <DataProvider>
        <Router>
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </Router>
      </DataProvider>
    </AuthProvider>
  )
};

export default App;
