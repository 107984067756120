import * as React from 'react';
import { Checkbox, Tab, TabTitleText, Tabs, Tooltip } from '@patternfly/react-core';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, } from '@patternfly/react-core';
import { useHistory } from "react-router-dom";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryTheme } from 'victory';
import { TreeViewDataItem } from '@patternfly/react-core';
import { ChartDonutThreshold, ChartDonutUtilization } from '@patternfly/react-charts';
import { element } from 'prop-types';
// import { Metrics } from '@kubernetes/client-node';
import { ClipboardCopy } from '@patternfly/react-core';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Progress } from '@patternfly/react-core';

import {
  Flex,
  FlexItem,
  Gallery,
  GalleryItem,
  MenuToggle,
  MenuToggleElement,
  Select,
  SelectList,
  SelectOption,
  Title
} from '@patternfly/react-core';
import { ChartArea, ChartGroup, ChartVoronoiContainer } from '@patternfly/react-charts';
import text from '@patternfly/react-styles/css/utilities/Text/text';
import { MonitorTab } from './Tabs/Monitor/MonitorTab';
import { Pod } from '../VMParser';
import { backendURL } from '../BackendURL';
import { DataContext } from '@app/DataContext';
import { getHeaders } from '../GetHeaders';
import { PvcDetailsView } from '@app/TreeViewImpl/PvcDetailsView';
import { ConfigureView } from '@app/TreeViewImpl/ConfigureView';

export interface AddNewVMModalProps {

}

export interface TreeViewProps {
  activeItem: TreeViewDataItem | undefined,
  item: Pod | undefined,
  imageUrl: string | undefined,
  openedShells: any,
  setOpenedShells: Dispatch<SetStateAction<any>>,
  iframeUrl: string,
  setIframeUrl: Dispatch<SetStateAction<string>>,
  currentProgress: number,
  setCurrentProgress: Dispatch<SetStateAction<number>>,
  isLoadingSsh: boolean,
  setIsLoadingSsh: Dispatch<SetStateAction<boolean>>,
  pvcNames: string[],
}
export const InnerTabs: React.FunctionComponent<TreeViewProps> =
  ({
    activeItem,
    item,
    imageUrl,
    openedShells,
    setOpenedShells,
    iframeUrl,
    setIframeUrl,
    currentProgress,
    setCurrentProgress,
    isLoadingSsh,
    setIsLoadingSsh,
    pvcNames,
  }) => {

    const { viewIndex, setViewIndex } = useContext(DataContext);
    const { refreshTree, setRefreshTree } = useContext(DataContext);

    const VMI_INDEX = 1;

    React.useEffect(() => {
      console.log('default constructor');
      if (viewIndex === VMI_INDEX) {
        setRefreshTree(refreshTree + 1);
      }
    }, [viewIndex])

    const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);
    const [isBox, setIsBox] = React.useState<boolean>(false);
    const { project, setProject } = useContext(DataContext);
    const TTL = 1000000;


    const showName = (name) => {
      return name.replace(" ", "").replace("⏵", "").replace("⏹", "").replace("⚠", "").replace("⏸", "");
    }

    const history = useHistory();

    // Toggle currently active tab
    const handleTabClick = (
      event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent,
      tabIndex: string | number
    ) => {
      setActiveTabKey(tabIndex);
    };

    const toggleBox = (checked: boolean) => {
      setIsBox(checked);
    };

    const handleClick = () => {
      history.push("/");
    };

    const data2012 = [
      { quarter: 1, earnings: 2 },
      { quarter: 2, earnings: 52 },
      { quarter: 3, earnings: 94 },
    ];

    const remoteConsoleHandler = (event) => {
      console.log(event, 'click');
      if (activeItem && item && item.printableStatus === 'Running') {
        const url = `https://novnc.com/noVNC/vnc.html?host=backend.dev.kube-dc.com&autoconnect=true&encrypt=true&path=apis/subresources.kubevirt.io/v1alpha3/namespaces/${project}/virtualmachineinstances/${showName(activeItem.name)}/vnc`;
        window.open(url, '_blank');
      }
    }


    const launchSshTerminal = (event) => {
      console.log('launchSshTerminal');
      if (item && item.ip !== 'Not Specified') {
        if (iframeUrl) {
          window.open(window['iframeUrl'], "_blank")?.focus();
        } else {
          setIframeAndFullscreenLink();
          setIsLoadingSsh(true);
          const intervalId = setInterval(() => {
            setCurrentProgress((prev) => {
              console.log(prev);
              if (prev >= 100) {
                clearInterval(intervalId);
                setIsLoadingSsh(false);
                console.log(window['iframeUrl']);
                window.open(window['iframeUrl'], "_blank")?.focus();
                return 100;
              }
              return prev + 7
            })
          }, 1000)
        }

      }
    }


    const setIframeAndFullscreenLink = () => {
      setIframeUrl('');

      if (!item || item.name === 'Not Specified') return;

      const iframe_url = openedShells[item!.name];
      if (!iframe_url) {
        fetch(`${backendURL}/api/cloud-shell/shalb-demo/generate?OS_IMAGE_USER=${item?.username}&VMI_IP=${item?.ip}`, {
          method: 'GET',
          headers: getHeaders(),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log('generate: ' + data);
            const svc_id = data.svc_id;
            const iframe_url = `${backendURL}/api/cloud-shell/shalb-demo/proxy?svc_id=` + svc_id;

            // 15s is enough for service to start
            setTimeout(() => {
              console.log('after setTimeout update iframe_url: ' + iframe_url, item, openedShells);
              setIframeUrl((prev) => { return iframe_url });
              window['iframeUrl'] = iframe_url;
              console.log('window  iframeUrl: ' + window['iframeUrl'])
              // window.open(iframe_url, "_blank")?.focus();


              const shells = { ...openedShells };
              shells[item!.name] = iframe_url;

              setOpenedShells(shells);
            }, 15000)

            // restart iframe with ssh every 100
            setTimeout(() => {
              console.log('delete openedShells and execute fn again ', item, openedShells);

              const shells = { ...openedShells };
              delete shells[item!.name];
              setOpenedShells(shells);
              setIframeUrl("");

              if (item) {
                setIframeAndFullscreenLink();
              }
            }, TTL)
          })
      } else {
        setIframeUrl((prev) => iframe_url);

        const shells = { ...openedShells };
        shells[item!.name] = iframe_url;

        setOpenedShells(shells);
      }



    }


    return (


      <React.Fragment>
        <Tabs
          activeKey={activeTabKey}
          onSelect={handleTabClick}
          isBox={isBox}
          aria-label="Tabs in the default example"
          role="region"
        >
          <Tab eventKey={0} title={<TabTitleText>Summary</TabTitleText>} aria-label="Default content - users">
            <div className="main-flexbox">
              <div className="main-card-container">
                <Card ouiaId="BasicCard">
                  <CardTitle>Guest OS</CardTitle>
                  <CardBody>
                    <img src={imageUrl} className="preview-square" />
                    <div onClick={remoteConsoleHandler} className="small-text remote-console-btn">Launch Remote Console</div>
                    {!isLoadingSsh && <div onClick={launchSshTerminal} className="small-text web-console-btn">Launch SSH Terminal</div>}
                    {isLoadingSsh && <div className='progress-bar-top-margin'><Progress value={currentProgress}></Progress></div>}
                  </CardBody>
                </Card>
              </div>
              <div className="main-card-container">
                <Card ouiaId="BasicCard">
                  <CardTitle>Virtual Machnie Details</CardTitle>
                  <CardBody>
                    <h4 className='small-text'>Guest OS</h4>
                    <p className='small-text'>{item && item.imageName || 'Not Specified'}</p>
                    <p className='small-text'>{item && item.kernel || ''}</p>
                    <br />
                    <h4 className='small-text'>Status</h4>
                    <p className='small-text'>{item?.printableStatus || 'Not Specified'} {item?.status}</p>
                    <br />
                    <h4 className='small-text'>Instance Type</h4>
                    <p className='small-text'>{item && item.instanceType || 'Not Specified'}</p>
                    <br />
                    <h4 className='small-text'>VPC Subnets</h4>
                    <p className='small-text'>{item && item.vpc}</p>
                    <p className='small-text'>{item && item.network}</p>
                    <br />
                    <h4 className='small-text'>Node Name</h4>
                    <p className='small-text'>{item && item.nodeName}</p>
                    <br />
                    <h4 className='small-text'>IP Adresses</h4>
                    <div className='small-text'>
                      {item && item.ip == 'Not Specified' && item.ip}
                      {item && item.ip != 'Not Specified' &&
                        <ClipboardCopy hoverTip="Copy" clickTip="Copied" variant="inline-compact">
                          {(item && item.ip) || ''}
                        </ClipboardCopy>
                      }

                    </div>
                    <br />
                  </CardBody>
                </Card>
              </div>
              <div className="main-card-container">
                <Card ouiaId="BasicCard">
                  <CardTitle>VM Hardware</CardTitle>
                  <CardBody>
                    <h4 className='small-text'>CPU</h4>
                    <p className='small-text'>{item && item.cores}</p>
                    <br />
                    <h4 className='small-text'>Memory</h4>
                    <p className='small-text'>{item && item.memory}</p>
                    <br />
                    <h4 className='small-text'>Hard Disk</h4>
                    {item && item.volumes.map(function (data) {
                      return (
                        <p key={data.mountPoint} className='small-text'>
                          {data.name} {data.type} {data.mountPoint}
                        </p>
                      )
                    })}
                    <br />
                    <h4 className='small-text'>Network Interface</h4>
                    {item && item.networkInterfaces.map(function (data) {
                      return (
                        <p key={data.name} className='small-text'>
                          {data.name} {data.name !== 'Not Specified'} {data.interfaceName}
                        </p>
                      )
                    })}
                    <br />
                    {/* <h4>GPU’s</h4>
                    <p>nvidia-a100-slice2</p> */}
                    <br />

                  </CardBody>
                </Card>
              </div>
              {/* <div className="main-card-container">
              <Card ouiaId="BasicCard">
                <CardTitle>Related Objects</CardTitle>
                <CardBody>
                  <h4>Infra Cluster</h4>
                  <p><a>Lab cluster</a></p>
                  <br />
                  <h4>Host</h4>
                  <p><a>uran4.demo.kube-dc.com</a></p>
                  <br />
                  <h4>Networks</h4>
                  <p><a>default/ovn-demo</a></p>
                  <br />
                  <h4>Storage</h4>
                  <p><a>rook-ceph-ebs</a></p>
                  <br />
                </CardBody>
              </Card>
            </div> */}
              <div className="charts-container">
                <Card ouiaId="BasicCard">
                  <CardTitle>Performance Metrics</CardTitle>
                  <CardBody>
                    {/* <h4 className='small-text'>Last update at 11:32 AM</h4> <br /> */}

                    <div className="inner-charts-container">

                      <div style={{ height: '230px', width: '230px' }}>
                        <h3 className="metrics-title">CPU</h3>
                        <ChartDonutThreshold
                          ariaDesc="Storage capacity"
                          ariaTitle="Donut utilization chart with static threshold example"
                          constrainToVisibleArea
                          data={[{ x: 'Warning at 60%', y: 60 }, { x: 'Danger at 90%', y: 90 }]}
                          labels={({ datum }) => datum.x ? datum.x : null}
                          name="chart10"
                        >
                          <ChartDonutUtilization
                            data={{ x: 'CPU capacity', y: item && item.metrics.cpu.percent }}
                            labels={({ datum }) => datum.x ? `${datum.x}: ${datum.y}%` : null}
                            subTitle=""
                            title={`${item && item.metrics.cpu.percent} %`}
                            thresholds={[{ value: 60 }, { value: 90 }]}
                          />
                        </ChartDonutThreshold>

                      </div>
                      <div style={{ height: '230px', width: '230px' }}>
                        <h3 className="metrics-title">Memory</h3>
                        <ChartDonutThreshold
                          ariaDesc="Storage capacity"
                          ariaTitle="Donut utilization chart with static threshold example"
                          constrainToVisibleArea
                          data={[{ x: 'Warning at 60%', y: 60 }, { x: 'Danger at 90%', y: 90 }]}
                          labels={({ datum }) => datum.x ? datum.x : null}
                          name="chart10"
                        >
                          <ChartDonutUtilization
                            data={{ x: 'Memory capacity', y: item && item.metrics.ram.percent }}
                            labels={({ datum }) => datum.x ? `${datum.x}: ${datum.y}%` : null}
                            subTitle={`of ${item && item.metrics.ram.total} Gib`}
                            title={` ${item && item.metrics.ram.used} Gib`}
                            thresholds={[{ value: 60 }, { value: 90 }]}
                          />
                        </ChartDonutThreshold>

                      </div>

                      <div style={{ height: '230px', width: '230px' }}>
                        <h3 className="metrics-title">Storage</h3>
                        <ChartDonutThreshold
                          ariaDesc="Storage capacity"
                          ariaTitle="Donut utilization chart with static threshold example"
                          constrainToVisibleArea
                          data={[{ x: 'Warning at 60%', y: 60 }, { x: 'Danger at 90%', y: 90 }]}
                          labels={({ datum }) => datum.x ? datum.x : null}
                          name="chart10"
                        >
                          <ChartDonutUtilization
                            data={{ x: 'Storage capacity', y: item && item.metrics.storage.percent }}
                            labels={({ datum }) => datum.x ? `${datum.x}: ${datum.y}%` : null}
                            subTitle={`of ${item && item.metrics.storage.total} Gib`}
                            title={`${item && item.metrics.storage.used} Gib`}
                            thresholds={[{ value: 60 }, { value: 90 }]}
                          />
                        </ChartDonutThreshold>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Tab>
          <Tab eventKey={1} title={<TabTitleText>Monitor</TabTitleText>}>
            <MonitorTab item={item}></MonitorTab>
          </Tab>
          <Tab eventKey={2} title={<TabTitleText>Configure</TabTitleText>}>
            <ConfigureView item={item}></ConfigureView>
          </Tab>
          <Tab eventKey={3} title={<TabTitleText>Volumes</TabTitleText>} >
            {pvcNames ? (
              pvcNames.map(pvcName => { return <PvcDetailsView item={item} pvcName={pvcName}></PvcDetailsView> })
            ) : null
            }

          </Tab>
          <Tab eventKey={4} title={<TabTitleText>Networks</TabTitleText>} >
            Networks
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  };