import { backendURL } from '@app/TreeContainer/BackendURL';
import * as React from 'react';
declare const moment: any;
import { ExpandableRowContent, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { Button, Popper, TextInput, Toolbar, ToolbarContent, ToolbarToggleGroup } from '@patternfly/react-core';
import { FilterIcon, TrashIcon } from '@patternfly/react-icons';
import { GreenCircle } from '@app/TreeContainer/indicators/GreenCircle';
import { getHeaders } from '@app/TreeContainer/GetHeaders';
import { useContext } from 'react';
import { DataContext } from '@app/DataContext';

export interface Label {
  value: string,
}


export interface Ingress {
  name: string;
  namespace: string;
  host: string;
  backendService: string;
  ip: string;
}

export const SystemIngressView: React.FunctionComponent = () => {
  const [ingresses, setIngresses] = React.useState<Ingress[]>([]);
  const [filteredIngresses, setFilteredIngresses] = React.useState<Ingress[]>([]);
  const { project, setProject } = useContext(DataContext);


  const getIngressData = () => {
    const url = `${backendURL}/api/system/${project}/ingresses`; // Update this URL to match your API
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then((res) => res.json())
      .then((data) => {
        const ingressList: Ingress[] = data.items.map((item: any) => {
          const host = item.spec.rules?.[0]?.host || '';
          const backendService = item.spec.rules?.[0]?.http?.paths?.[0]?.backend?.service?.name || '';
          const ip = item.status?.loadBalancer?.ingress?.[0]?.ip || '';

          return {
            name: item.metadata.name,
            namespace: item.metadata.namespace,
            host,
            backendService,
            ip
          };
        });
        setIngresses(ingressList);
        setFilteredIngresses(ingressList);
      });
  };

  React.useEffect(() => {
    getIngressData();
  }, [])

  const columnNames = {
    name: 'Ingress Name',
    namespace: 'Namespace',
    host: 'Host',
    backendService: 'Backend Service',
    ip: 'IP Address'
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const [locationSelections, setLocationSelections] = React.useState<string[]>([]);
  const [filterOptions, setFilterOptions] = React.useState<string[]>(['Running', 'Pending']);
  const [statusSelections, setStatusSelections] = React.useState<string[]>(['Running', 'Pending']);
  const [hostsSelections, setHostsSelections] = React.useState<string[]>([]);


  const areSelectionsPresent = locationSelections.length > 0 || statusSelections.length > 0 || hostsSelections.length > 0;
  const toggleRef = React.useRef<HTMLButtonElement>(null);
  const toggle = (
    <></>
  );

  const _isSelected = (_type) => {
    return statusSelections.includes(_type)
  }

  const menuRef = React.useRef<HTMLDivElement>(null);
  const menu = (
    <></>
  );

  const isRepoSelectable = (repo: Ingress) => repo.name !== 'a';
  const selectableRepos = ingresses.filter(isRepoSelectable);
  const [selectedRepoNames, setSelectedRepoNames] = React.useState<string[]>([]);
  const setRepoSelected = (repo: Ingress, isSelecting = true) =>
    setSelectedRepoNames((prevSelected) => {
      const otherSelectedRepoNames = prevSelected.filter((r) => r !== repo.name);
      return isSelecting && isRepoSelectable(repo) ? [...otherSelectedRepoNames, repo.name] : otherSelectedRepoNames;
    });
  const selectAllRepos = (isSelecting = true) =>
    setSelectedRepoNames(isSelecting ? selectableRepos.map((r) => r.name) : []);
  const areAllReposSelected = selectedRepoNames.length === selectableRepos.length;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const select = (
    <div ref={containerRef}>
      <Popper
        trigger={toggle}
        triggerRef={toggleRef}
        popper={menu}
        popperRef={menuRef}
        appendTo={containerRef.current || undefined}
        isVisible={isOpen}
      />
    </div>
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (isOpen && !menuRef.current?.contains(event.target as Node) && !toggleRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, menuRef]);

  const onChipDelete = (category: string, chip: string) => {
    if (category === 'status') {
      setStatusSelections(statusSelections.filter((selection) => selection !== chip));
    } else {
      setLocationSelections(locationSelections.filter((selection) => selection !== chip));
    }
  };

  const toolbar = (
    <Toolbar
      id="filter-faceted-toolbar"
      clearAllFilters={() => {
      }}
    >
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          {/* <ToolbarFilter
            chips={statusSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setStatusSelections([])}
            categoryName="Status"
            showToolbarItem={false}
          >
            <div />
          </ToolbarFilter> */}
          {/* <ToolbarFilter
            chips={locationSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setLocationSelections([])}
            categoryName="Labels"
          > */}
          {select}
          {/* </ToolbarFilter> */}
        </ToolbarToggleGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const [value, setValue] = React.useState('');

  const initialExpandedRepoNames = []; // Default to all expanded
  const [expandedRepoNames, setExpandedRepoNames] = React.useState<string[]>(initialExpandedRepoNames);
  const setRepoExpanded = (service: Ingress, isExpanding = true) =>
    setExpandedRepoNames((prevExpanded) => {
      const otherExpandedRepoNames = prevExpanded.filter((r) => r !== service.name);
      return isExpanding ? [...otherExpandedRepoNames, service.name] : otherExpandedRepoNames;
    });
  const isServiceExpanded = (repo: Ingress) => expandedRepoNames.includes(repo.name);



  // selection
  const isRepoSelected = (repo: Ingress) => selectedRepoNames.includes(repo.name);

  const [shifting, setShifting] = React.useState(false);
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = React.useState<number | null>(null);

  const onSelectRepo = (repo: Ingress, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex;
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex);
      intermediateIndexes.forEach((index) => setRepoSelected(ingresses[index], isSelecting));
    } else {
      setRepoSelected(repo, isSelecting);
    }
    setRecentSelectedRowIndex(rowIndex);
  };

  const onDeleteClick = () => {
    console.log('selected', selectedRepoNames);
  }


  return (
    <React.Fragment>
      <div className='controls-pane'>

        <div className='top-padding-controls-toolbar'>

          <TextInput
            className='server-name-filter deployment-filter-margin'
            placeholder="Filter by name"
            value={value}
            type="text"
            onChange={
              (_event, value) => {
                setValue(value);
                const filteredIngresses = ingresses.filter((item: Ingress) => {
                  console.log('includes:', item.name.includes(value));
                  return item.name.includes(value);
                })
                setFilteredIngresses(filteredIngresses);
              }
            }
            aria-label="text input example"
          />

        </div>
        <div className='top-padding-controls-toolbar'>
          <Button onClick={onDeleteClick} variant="plain" aria-label="Action">
            <TrashIcon />
          </Button>

        </div>
      </div>

      <Table
        variant="compact"
        aria-label="Simple table">
        <Thead>
          <Tr>
            <Th
              select={{
                onSelect: (_event, isSelecting) => selectAllRepos(isSelecting),
                isSelected: areAllReposSelected
              }}
              aria-label="Row select"
            />
            <Th />
            <Th>{columnNames.name}</Th>
            <Th>Status</Th>
            <Th>{columnNames.namespace}</Th>
            <Th>{columnNames.host}</Th>
            <Th>{columnNames.ip}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredIngresses.map((item: Ingress, rowIndex) => {
            return <>
              <Tr key={item.name}>
                <Td
                  select={{
                    rowIndex,
                    onSelect: (_event, isSelecting) => onSelectRepo(item, rowIndex, isSelecting),
                    isSelected: isRepoSelected(item),
                    isDisabled: !isRepoSelectable(item)
                  }}
                />
                <Td />
                <Td>{item.name}</Td>
                <Td>
                  <div className='status-container'><GreenCircle></GreenCircle> Active</div>
                </Td>
                <Td>{item.namespace}</Td>
                <Td>{item.host}</Td>
                <Td>{item.ip}</Td>
              </Tr>
              {item.name ? (
                <>
                  <Tr isExpanded={isServiceExpanded(item)}>
                    <Td dataLabel="Repo detail 0">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 1">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 2">
                      <ExpandableRowContent>
                        <div className='bold-text metadata-title'>Labels</div>
                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 3" colSpan={2}>
                      <ExpandableRowContent>
                      </ExpandableRowContent>
                    </Td>

                  </Tr>

                </>
              ) : null}
            </>
          })}
        </Tbody>
      </Table>

    </React.Fragment>
  );
};