import * as React from 'react';
import CubesIcon from '@patternfly/react-icons/dist/esm/icons/cubes-icon';

import { Button, TreeView, TreeViewDataItem } from '@patternfly/react-core';
import { backendURL } from '@app/TreeContainer/BackendURL';
import { getHeaders } from '@app/TreeContainer/GetHeaders';
import { useContext } from 'react';
import { DataContext } from '@app/DataContext';

export interface TreeViewProps {
  onSelect?: (event: React.MouseEvent, item: TreeViewDataItem, parentItem: TreeViewDataItem) => void;
  /** Callback for expanding a node with children. */
}
export const PodTreeViewImpl: React.FunctionComponent<TreeViewProps> = ({ onSelect }) => {
  const [activeItems, setActiveItems] = React.useState<TreeViewDataItem[]>();
  const [allExpanded, setAllExpanded] = React.useState<boolean>(true);
  const { project, setProject } = useContext(DataContext);

  const onToggle = (_event: React.MouseEvent) => {
    setAllExpanded((prevAllExpanded) => !prevAllExpanded);
  };


  const [options, setOptions] = React.useState([{
    name: 'Loading...',
    id: 'loading',
    children: []
  }]);
  React.useEffect(() => {
    fetch(`${backendURL}/api/system/${project}/tree-structure`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        // options = data;
        setOptions(data);
      })

    return () => { };
  }, []);


  return (
    <React.Fragment>
      <TreeView hasSelectableNodes data={options} activeItems={activeItems} onSelect={onSelect} allExpanded={allExpanded} icon={<CubesIcon />}
        expandedIcon={<CubesIcon />}></TreeView>
    </React.Fragment>
  );
};

