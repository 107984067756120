import * as React from 'react';
import { Button, Masthead, MastheadBrand, MastheadMain, PageSection, TextInput, Title, TreeViewDataItem } from '@patternfly/react-core';
import { Sidebar } from '@app/TreeViewImpl/Sidebar';
import { VirtualMachineInstance } from './VirtualMachineInstance/VirtualMachineInstance';
import { ProjectView } from './ProjectView';
import { VirtualMachinesView } from './VirtualMachinesView';
import { Pod, emptyPod, parseVM } from './VMParser';
import { backendURL } from './BackendURL';
import { DataContext } from '@app/DataContext';
import { useContext } from 'react';
import { SystemPodView } from '@app/TreeViewImpl/SystemPodView';
import { SystemServiceView } from '@app/TreeViewImpl/SystemServiceView';
import { SystemDeploymentView } from '@app/TreeViewImpl/SystemDeploymentView';
import { SystemJobView } from '@app/TreeViewImpl/SystemJobView';
import { SystemDaemonSetView } from '@app/TreeViewImpl/SystemDaemonSetView';
import { SystemStatefulSetView } from '@app/TreeViewImpl/SystemStatefulSetView';
import { SystemReplicaSetView } from '@app/TreeViewImpl/SystemReplicaSetView';
import { SystemIngressView } from '@app/TreeViewImpl/SystemIngressView';
import { SystemConfigMapView } from '@app/TreeViewImpl/SystemConfigMapView';
import { SystemSecretView } from '@app/TreeViewImpl/SystemSecretView';
import { PvcDetailsView } from '@app/TreeViewImpl/PvcDetailsView';
import { ProjectSelector } from '@app/AppLayout/ProjectSelector';
import { SearchAll } from '@app/AppLayout/SearchAll';
import { NavActionButtons } from '@app/AppLayout/NavActionButtons';
import { BundleIcon } from '@patternfly/react-icons';
import { getHeaders } from './GetHeaders';

import { hasAuthParams, useAuth } from "react-oidc-context";
import { DeleteVMModal } from './VirtualMachineInstance/Tabs/DeleteVMModal';
import { AddNewVMModal } from './VirtualMachineInstance/AddNewVMModal';




const TreeContainer: React.FunctionComponent = () => {
  const [openedShells, setOpenedShells] = React.useState<any>({});
  const [item, setItem] = React.useState<Pod>();
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [activeItem, setActiveItem] = React.useState<TreeViewDataItem>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [iframeUrl, setIframeUrl] = React.useState<string>("");
  const [currentProgress, setCurrentProgress] = React.useState<number>(0);
  const [isLoadingSsh, setIsLoadingSsh] = React.useState<boolean>(false);

  const [pvcName, setPvcName] = React.useState<string>('');
  const [pvcNames, setPvcNames] = React.useState<string[]>([]);

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [organization, setOrganization] = React.useState('');

  const { givenName, setGivenName } = useContext(DataContext);
  const { familyName, setFamilyName } = useContext(DataContext);
  const { email, setEmail } = useContext(DataContext);

  const { project, setProject } = useContext(DataContext);
  const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  const VMI_INDEX = 1;
  const VM_INDEX = 2;
  const NAMESPACE_INDEX = 3;
  const K8S_INDEX = 4;
  const K8S_PROJECT_INDEX = 5;

  const VIRTUAL_MACHINES_ID: number = 2222;
  const NAMESPACE_ID: number = 1111;
  const K8S_ID: number = 3333;
  const K8S_ID_PROJECT: number = 4444;

  const CRON_JOBS = 1981;
  const DAEMON_SETS = 1982;
  const DEPLOYMENTS = 1983;
  const JOBS = 1984;
  const PODS = 1985;
  const REPLICA_SETS = 1986;
  const REPLICATION_CONTROLLERS = 1987;
  const STATEFUL_SETS = 1988;

  const POD_AUTOSCALING = 1990;
  const INGRESSES = 1991;
  const SERVICES = 1992;

  const CONFIG_MAPS = 1994;
  const PVC = 1995;
  const PVC_START = 10000;
  const PVC_END = 11000;
  const SECRETS = 1996;
  const SERVICE_ACCOUNTS = 1997;

  const { viewIndex, setViewIndex } = useContext(DataContext);
  const { refreshTree, setRefreshTree } = useContext(DataContext);

  // automatically sign-in
  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const realm = urlParams.get('realm');

    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin && realm
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  // set token
  React.useEffect(() => {
    (async () => {
      if (auth.isAuthenticated) {
        try {
          const token = auth.user?.access_token;
          localStorage.setItem('token', token!);
          setGivenName(auth.user?.profile?.given_name!);
          setFamilyName(auth.user?.profile?.family_name!);
          setEmail(auth.user?.profile?.email!);
        } catch (e) {
          console.error(e);
        }

        let pageUrl = localStorage.getItem('redirectToPage');
        console.log('redirectToPage', pageUrl);
        if (pageUrl) {
          location.hash = pageUrl;
          if (pageUrl.startsWith('#vmi/')) {
            let name = pageUrl.slice('#vmi/'.length);
            let activeItem: TreeViewDataItem = { name: name };
            onSelectTreeViewDataItem(activeItem);
          }
          if (pageUrl.startsWith('#volume/')) {
            let name = pageUrl.slice('#volume/'.length);
            let activeItem: TreeViewDataItem = { name: name, id: PVC_START + '' };
            let item = activeItem as any;
            item.inner_name = name;
            onSelectTreeViewDataItem(item);
            let volumeTabIndex = 3;
            setActiveTabKey(volumeTabIndex);
          }

          if (pageUrl.startsWith('#vm-list')) {
            let name = 'Virtual Machines';
            let activeItem: TreeViewDataItem = { name: name, id: VIRTUAL_MACHINES_ID + '' };
            onSelectTreeViewDataItem(activeItem);
          }
        }

        let resp = await fetch(`${backendURL}/api/project`, {
          method: 'GET',
          headers: getHeaders(),
        });
        let json = await resp.json();
        console.log('PROJECTS', json);

        setProject('shalb-demo');
        localStorage.setItem('project', 'shalb-demo');

      } else if (location.hash) {
        localStorage.setItem('redirectToPage', location.hash);
        history.pushState("", document.title, window.location.pathname
          + window.location.search);
      }
    })();
  }, [auth]);

  // refresh before expiration
  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    })
  }, [auth.events, auth.signinSilent]);

  React.useEffect(() => {
    let _project = localStorage.getItem('project');
    if (!project && _project) {
      setProject(_project);
    }
  }, [project]);



  React.useEffect(() => {
    let org = localStorage.getItem('organization') || 'shalb';
    localStorage.setItem('organization', org);
    if (!organization) {
      setOrganization(org);
    }
  }, [organization]);

  React.useEffect(() => {
    if (!activeItem) return;

    async function fetchVMI(activeItem) {
      const url = `${backendURL}/api/virtual-machines/${project}/vmi/` + showName(activeItem.name);
      const response = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
      });
      let data = await response.json();

      console.log(data);
      const skipMetrics = false;
      const item = parseVM(data, skipMetrics);
      setItem(item);

      let dataVolumeName = item.dataVolumes[0].name;

      let _pvcNames: string[] = [];
      for await (const _item of item.dataVolumes) {
        data = await fetch(`${backendURL}/api/volume/${project}/persistentvolumeclaims/${_item.name}`, {
          method: 'GET',
          headers: getHeaders(),
        });
        const json = await data.json();
        _pvcNames.push(json.spec.volumeName);
      }
      setPvcNames(_pvcNames);

    }

    fetchVMI(activeItem);
  }, [refreshTree])


  React.useEffect(() => {
    console.log(activeItem, '- Has changed')

    setIframeUrl('');
    setCurrentProgress(0);
    setIsLoadingSsh(false);

    const empty_pod: Pod = emptyPod();
    setItem(empty_pod);
    setImageUrl("");

    if (!activeItem) return;

    const _image_url = `${backendURL}/apis/subresources.kubevirt.io/v1alpha3/namespaces/${project}/virtualmachineinstances/` + showName(activeItem.name) + '/vnc/screenshot';
    setImageUrl(_image_url);
    const url = `${backendURL}/api/virtual-machines/${project}/vmi/` + showName(activeItem.name);
    const response = fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        const skipMetrics = false;
        const item = parseVM(data, skipMetrics);
        setItem(item);
      })

  }, [activeItem])


  const onSelectTreeViewDataItem = (treeViewItem: TreeViewDataItem) => {
    console.log('onSelect2');
    const id = parseInt(treeViewItem.id || '0');

    if (id === VIRTUAL_MACHINES_ID) {
      setViewIndex(VM_INDEX);
      location.hash = '#vm-list';
      return;
    }
    if (id === NAMESPACE_ID) {
      setViewIndex(NAMESPACE_INDEX);
      history.pushState("", document.title, window.location.pathname
        + window.location.search);
      return;
    }
    if (id === K8S_ID) {
      setViewIndex(K8S_INDEX);
      return;
    }
    if (id >= K8S_ID_PROJECT && id < 5000) {
      setViewIndex(K8S_PROJECT_INDEX);
      return;
    }
    if (id === CRON_JOBS) {
      setViewIndex(CRON_JOBS);
      return
    }

    if (id === DAEMON_SETS) {
      setViewIndex(DAEMON_SETS);
      return
    }

    if (id === DEPLOYMENTS) {
      setViewIndex(DEPLOYMENTS);
      return
    }


    if (id === JOBS) {
      setViewIndex(JOBS);
      return
    }

    if (id === PODS) {
      setViewIndex(PODS);
      return
    }

    if (id === REPLICA_SETS) {
      setViewIndex(REPLICA_SETS);
      return
    }

    if (id === REPLICATION_CONTROLLERS) {
      setViewIndex(REPLICATION_CONTROLLERS);
      return
    }

    if (id === STATEFUL_SETS) {
      setViewIndex(STATEFUL_SETS);
      return
    }

    if (id === POD_AUTOSCALING) {
      setViewIndex(POD_AUTOSCALING);
      return
    }

    if (id === INGRESSES) {
      setViewIndex(INGRESSES);
      return
    }

    if (id === SERVICES) {
      setViewIndex(SERVICES);
      return
    }

    if (id === CONFIG_MAPS) {
      setViewIndex(CONFIG_MAPS);
      return
    }

    if (id >= PVC_START && id < PVC_END) {
      let tree = treeViewItem as any;
      setPvcName(tree.inner_name);
      setViewIndex(PVC);
      location.hash = '#volume/' + tree.inner_name;
      return
    }

    if (id === SECRETS) {
      setViewIndex(SECRETS);
      return
    }

    if (id === SERVICE_ACCOUNTS) {
      setViewIndex(SERVICE_ACCOUNTS);
      return
    }


    if (treeViewItem && !treeViewItem.children) {
      setViewIndex(VMI_INDEX);
      setActiveItem(treeViewItem);
      console.log('onSelect3')
      location.hash = '#vmi/' + showName(treeViewItem.name);
    }
  }

  const onSelect = (_event: React.MouseEvent, treeViewItem: TreeViewDataItem) => {
    onSelectTreeViewDataItem(treeViewItem);
  };

  const showName = (name) => {
    return name.replace(" ", "").replace("⏵", "").replace("⏹", "").replace("⚠", "").replace("⏸", "");
  }

  const logoClick = () => {
    let item: TreeViewDataItem = {
      name: 'Namespaces',
      id: NAMESPACE_ID + ''
    };
    onSelectTreeViewDataItem(item);
  };

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <React.Fragment>
        <Masthead className='masthead-style'>
          <MastheadMain>
            <MastheadBrand>
              <span className="main-logo"><BundleIcon /></span>
            </MastheadBrand>
            <span onClick={logoClick} className="logo-typed">KUBE-DC</span>
            <ProjectSelector></ProjectSelector>
            <SearchAll></SearchAll>
          </MastheadMain>
          <NavActionButtons></NavActionButtons>
        </Masthead>

        <PageSection>
          <div className="full-page">
            <div className="sidebar">
              <div className="sidebar-overflow">
                <Sidebar setActiveTabKey={setActiveTabKey} activeTabKey={activeTabKey} onSelect={onSelect} />
              </div>
            </div>
            <div className="fullpage-content">

              {viewIndex === VMI_INDEX && <VirtualMachineInstance
                item={item}
                imageUrl={imageUrl}
                openedShells={openedShells}
                setOpenedShells={setOpenedShells}
                iframeUrl={iframeUrl}
                setIframeUrl={setIframeUrl}
                currentProgress={currentProgress}
                setCurrentProgress={setCurrentProgress}
                isLoadingSsh={isLoadingSsh}
                setIsLoadingSsh={setIsLoadingSsh}
                activeItem={activeItem}
                pvcNames={pvcNames}
              ></VirtualMachineInstance>}

              {viewIndex === VM_INDEX && <React.Fragment><VirtualMachinesView></VirtualMachinesView></React.Fragment>}
              {viewIndex === NAMESPACE_INDEX && <React.Fragment><ProjectView></ProjectView></React.Fragment>}
              {viewIndex === K8S_INDEX && <React.Fragment>K8S</React.Fragment>}
              {viewIndex === K8S_PROJECT_INDEX && <React.Fragment>K8S Project</React.Fragment>}

              {viewIndex === CRON_JOBS && <React.Fragment>CRON_JOBS</React.Fragment>}
              {viewIndex === DAEMON_SETS && <React.Fragment><SystemDaemonSetView></SystemDaemonSetView></React.Fragment>}
              {viewIndex === DEPLOYMENTS && <React.Fragment><SystemDeploymentView></SystemDeploymentView></React.Fragment>}
              {viewIndex === JOBS && <React.Fragment><SystemJobView></SystemJobView></React.Fragment>}
              {viewIndex === PODS && <React.Fragment><SystemPodView></SystemPodView></React.Fragment>}
              {viewIndex === REPLICA_SETS && <React.Fragment><SystemReplicaSetView></SystemReplicaSetView></React.Fragment>}
              {viewIndex === REPLICATION_CONTROLLERS && <React.Fragment>REPLICATION_CONTROLLERS</React.Fragment>}
              {viewIndex === STATEFUL_SETS && <React.Fragment><SystemStatefulSetView></SystemStatefulSetView></React.Fragment>}

              {viewIndex === POD_AUTOSCALING && <React.Fragment>POD_AUTOSCALING</React.Fragment>}
              {viewIndex === INGRESSES && <React.Fragment><SystemIngressView></SystemIngressView></React.Fragment>}
              {viewIndex === SERVICES && <React.Fragment><SystemServiceView></SystemServiceView></React.Fragment>}

              {viewIndex === CONFIG_MAPS && <React.Fragment><SystemConfigMapView></SystemConfigMapView></React.Fragment>}
              {viewIndex === PVC && <React.Fragment><PvcDetailsView pvcName={pvcName}></PvcDetailsView> </React.Fragment>}
              {viewIndex === SECRETS && <React.Fragment><SystemSecretView></SystemSecretView></React.Fragment>}
              {viewIndex === SERVICE_ACCOUNTS && <React.Fragment>SERVICE_ACCOUNTS</React.Fragment>}

            </div>
          </div>
        </PageSection>
        <DeleteVMModal></DeleteVMModal>
        <AddNewVMModal></AddNewVMModal>
      </React.Fragment>
    );
  } else {
    return (
      <div className='login-page-flexbox'>
        <div>
          <Title headingLevel="h1">Please log in</Title>
          <br />
          <Title headingLevel="h4">Enter organization name:</Title>
          <br />
          <TextInput
            value={organization}
            type="text"
            onChange={(_event, value) => {
              localStorage.setItem('organization', value);
              setOrganization(value);
            }}
            aria-label="text input example"
          />
          <br />
          <Button onClick={() => {
            localStorage.setItem('organization', organization);
            location.search = `realm=${organization}`
          }} variant="primary" ouiaId="Primary">
            Proceed To Login
          </Button>
        </div>
      </div>
    );
  }

}

export { TreeContainer };
