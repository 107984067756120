import { DataContext } from '@app/DataContext';
import { backendURL } from '@app/TreeContainer/BackendURL';
import { getHeaders } from '@app/TreeContainer/GetHeaders';
import { CodeEditor, Language } from '@patternfly/react-code-editor';
import { Button, TextInput } from '@patternfly/react-core';
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import * as React from 'react';
import { useContext } from 'react';

interface Secret {
  name: string;
  created: string;
}


export const SystemSecretView: React.FunctionComponent = () => {
  const [secrets, setSecrets] = React.useState<Secret[]>([]);
  const [filteredSecrets, setFilteredSecrets] = React.useState<Secret[]>([]);
  const [selectedSecretName, setSelectedSecretName] = React.useState('');
  const [selectedSecret, setSelectedSecret] = React.useState('Select secret');
  const { notifications, setNotifications } = useContext(DataContext);
  const { project, setProject } = useContext(DataContext);

  const [value, setValue] = React.useState('');

  const columnNames = {
    name: 'Name',
    uid: 'UID',
    created: 'Created',
  };

  const getSecrets = async () => {
    try {
      const response = await fetch(`${backendURL}/api/system/${project}/secrets`, {
        method: 'GET',
        headers: getHeaders(),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const resp = await response.json();
      let json_items: Secret[] = [];
      resp.items.forEach((item) => {
        let secret = {
          name: item.metadata.name,
          created: item.metadata.creationTimestamp,
        }
        json_items.push(secret)
        console.log('output', json_items);
      })
      setSecrets(json_items);
      setFilteredSecrets(json_items);
    } catch (error) {
      console.error('Failed to fetch stateful sets:', error);
    }
  };

  React.useEffect(() => {
    getSecrets();
  }, []);


  const onChange = (value) => {
    // eslint-disable-next-line no-console
    console.log(value);
    setSelectedSecret(value);
  };

  const onEditorDidMount = (editor, monaco) => {
    editor.layout();
    editor.focus();
    monaco.editor.getModels()[0].updateOptions({ tabSize: 5 });
  };

  const rowClicked = async (secret: Secret) => {
    setSelectedSecretName(secret.name);

    const response = await fetch(`${backendURL}/api/system/${project}/secrets/${secret.name}`, {
      method: 'GET',
      headers: getHeaders(),
    });
    if (!response.ok) throw new Error('Network response was not ok');
    const resp = await response.text();
    setSelectedSecret(resp)

    console.log(resp);
  }

  const updateMap = async () => {
    let headers = getHeaders();
    headers["Content-Type"] = "application/yaml";
    const response = await fetch(`${backendURL}/api/system/${project}/secrets/${selectedSecretName}`, {
      headers: headers,
      method: "PUT",
      body: selectedSecret
    });
    if (!response.ok) throw new Error('Network response was not ok');
    const resp = await response.text();

    console.log(resp);

    const notification = `Secret '${selectedSecretName}' successfully updated.`;
    const notifications_local = [notification, ...notifications];
    setNotifications(notifications_local);
  }


  return (
    <React.Fragment>
      <div className='controls-pane'>

        <div className='top-padding-controls-toolbar'>

          <TextInput
            className='server-name-filter deployment-filter-margin'
            placeholder="Filter by name"
            value={value}
            type="text"
            onChange={
              (_event, value) => {
                setValue(value);
                const filteredSecrets = secrets.filter((item: Secret) => {
                  console.log('includes:', item.name.includes(value));
                  return item.name.includes(value);
                })
                setFilteredSecrets(filteredSecrets);
              }
            }
            aria-label="text input example"
          />

        </div>
        <div className='top-padding-controls-toolbar'>

        </div>
      </div>

      <div className='configmap-flex-container'>

        <div className='configmap-flex-container-left-pane'>
          <div className='config-map-height-table'>
            <Table aria-label="Clickable table">
              <Thead>
                <Tr>
                  <Th>{columnNames.name}</Th>
                  <Th>{columnNames.created}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredSecrets.map((secret) => (
                  <Tr
                    key={secret.name}
                    onRowClick={() => {
                      rowClicked(secret)

                    }}
                    isSelectable
                    isClickable
                    isRowSelected={selectedSecretName === secret.name}
                  >
                    <Td dataLabel={columnNames.name}>{secret.name}</Td>
                    <Td dataLabel={columnNames.created}>{secret.created}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>

        <div className='configmap-flex-container-left-pane'>
          <CodeEditor
            isDarkTheme={true}
            isLineNumbersVisible={true}
            isReadOnly={false}
            isMinimapVisible={false}
            isLanguageLabelVisible
            code={selectedSecret}
            onChange={onChange}
            language={Language.yaml}
            onEditorDidMount={onEditorDidMount}
            height={(window.innerHeight - 320) + 'px'}
          />

          <Button className='configmap-update-btn'
            onClick={updateMap}
            variant="primary"
            ouiaId="Primary">
            Update
          </Button>
        </div>

      </div>



    </React.Fragment>
  );
};