import { DataContext } from '@app/DataContext';
import { backendURL } from '@app/TreeContainer/BackendURL';
import { getHeaders } from '@app/TreeContainer/GetHeaders';
import { CodeEditor, Language } from '@patternfly/react-code-editor';
import { Button, TextInput } from '@patternfly/react-core';
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import * as React from 'react';
import { useContext } from 'react';

interface ConfigMap {
  name: string;
  uid: string;
  created: string;
  configMap: string;
}


export const SystemConfigMapView: React.FunctionComponent = () => {
  const [configMaps, setConfigMaps] = React.useState<ConfigMap[]>([]);
  const [filteredConfigMaps, setFilteredConfigMaps] = React.useState<ConfigMap[]>([]);
  const [selectedRepoName, setSelectedRepoName] = React.useState('');
  const [selectedConfigMap, setSelectedConfigMap] = React.useState('Select config map');
  const { notifications, setNotifications } = useContext(DataContext);
  const { project, setProject } = useContext(DataContext);
  const [value, setValue] = React.useState('');

  const columnNames = {
    name: 'Name',
    uid: 'UID',
    created: 'Created',
  };

  const getConfigMaps = async () => {
    try {
      const response = await fetch(`${backendURL}/api/system/${project}/configmaps`, {
        method: 'GET',
        headers: getHeaders(),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const resp = await response.json();
      let json_items: ConfigMap[] = [];
      resp.items.forEach((item) => {
        let configMap = {
          name: item.metadata.name,
          uid: item.metadata.uid,
          created: item.metadata.creationTimestamp,
          configMap: JSON.stringify(item)
        }
        json_items.push(configMap)
        console.log('output', json_items);
      })
      setConfigMaps(json_items);
      setFilteredConfigMaps(json_items);
    } catch (error) {
      console.error('Failed to fetch stateful sets:', error);
    }
  };

  React.useEffect(() => {
    getConfigMaps();
  }, []);


  const onChange = (value) => {
    // eslint-disable-next-line no-console
    console.log(value);
    setSelectedConfigMap(value);
  };

  const onEditorDidMount = (editor, monaco) => {
    editor.layout();
    editor.focus();
    monaco.editor.getModels()[0].updateOptions({ tabSize: 5 });
  };

  const rowClicked = async (configMap: ConfigMap) => {
    setSelectedRepoName(configMap.name);

    const response = await fetch(`${backendURL}/api/system/${project}/configmaps/${configMap.name}`, {
      method: 'GET',
      headers: getHeaders(),
    });
    if (!response.ok) throw new Error('Network response was not ok');
    const resp = await response.text();
    setSelectedConfigMap(resp)

    console.log(resp);
  }

  const updateMap = async () => {
    let headers = getHeaders();
    headers["Content-Type"] = "application/yaml";
    const response = await fetch(`${backendURL}/api/system/${project}/configmaps/${selectedRepoName}`, {
      headers: headers,
      method: "PUT",
      body: selectedConfigMap
    });
    if (!response.ok) throw new Error('Network response was not ok');
    const resp = await response.text();

    console.log(resp);

    const notification = `ConfigMap '${selectedRepoName}' successfully updated.`;
    const notifications_local = [notification, ...notifications];
    setNotifications(notifications_local);
  }


  return (
    <React.Fragment>
      <div className='controls-pane'>

        <div className='top-padding-controls-toolbar'>

          <TextInput
            className='server-name-filter deployment-filter-margin'
            placeholder="Filter by name"
            value={value}
            type="text"
            onChange={
              (_event, value) => {
                setValue(value);
                const filteredConfigMaps = configMaps.filter((item: ConfigMap) => {
                  console.log('includes:', item.name.includes(value));
                  return item.name.includes(value);
                })
                setFilteredConfigMaps(filteredConfigMaps);
              }
            }
            aria-label="text input example"
          />

        </div>
        <div className='top-padding-controls-toolbar'>

        </div>
      </div>

      <div className='configmap-flex-container'>

        <div className='configmap-flex-container-left-pane'>
          <div className='config-map-height-table'>
            <Table aria-label="Clickable table">
              <Thead>
                <Tr>
                  <Th>{columnNames.name}</Th>
                  <Th>{columnNames.created}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredConfigMaps.map((configMap) => (
                  <Tr
                    key={configMap.name}
                    onRowClick={() => {
                      rowClicked(configMap)

                    }}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRepoName === configMap.name}
                  >
                    <Td dataLabel={columnNames.name}>{configMap.name}</Td>
                    <Td dataLabel={columnNames.created}>{configMap.created}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>

        <div className='configmap-flex-container-left-pane'>
          <CodeEditor
            isDarkTheme={true}
            isLineNumbersVisible={true}
            isReadOnly={false}
            isMinimapVisible={false}
            isLanguageLabelVisible
            code={selectedConfigMap}
            onChange={onChange}
            language={Language.yaml}
            onEditorDidMount={onEditorDidMount}
            height={(window.innerHeight - 320) + 'px'}
          />

          <Button className='configmap-update-btn'
            onClick={updateMap}
            variant="primary"
            ouiaId="Primary">
            Update
          </Button>
        </div>

      </div>



    </React.Fragment>
  );
};