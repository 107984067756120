import * as React from 'react';
import { Button, Tab, TabContent, TabTitleText, Tabs, TreeViewDataItem } from '@patternfly/react-core';
import { Spinner } from '@patternfly/react-core';
import { Progress } from '@patternfly/react-core';
import { CloseIcon, ExclamationCircleIcon, ExternalLinkAltIcon } from '@patternfly/react-icons';
import { Dispatch, SetStateAction } from 'react';

import { Caption, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { CheckCircleIcon } from '@patternfly/react-icons'
import { Pod } from '@app/TreeContainer/VMParser';
import { backendURL } from '@app/TreeContainer/BackendURL';
import { getHeaders } from '@app/TreeContainer/GetHeaders';

export interface BottomPanelProps {
  item: Pod | undefined,
  openedShells: any,
  setOpenedShells: Dispatch<SetStateAction<any>>,
  iframeUrl: string,
  setIframeUrl: Dispatch<SetStateAction<string>>,
  currentProgress: number,
  setCurrentProgress: Dispatch<SetStateAction<number>>,
  isLoadingSsh: boolean,
  setIsLoadingSsh: Dispatch<SetStateAction<boolean>>,
}
export const BottomPanel: React.FunctionComponent<BottomPanelProps> =
  ({ item,
    openedShells,
    setOpenedShells,
    iframeUrl,
    setIframeUrl,
    currentProgress,
    setCurrentProgress,
    isLoadingSsh,
    setIsLoadingSsh,
  }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const TTL = 1000000;


    const setIframeAndFullscreenLink = () => {
      setIframeUrl('');

      if (!item || item.name === 'Not Specified') return;

      const iframe_url = openedShells[item!.name];
      if (!iframe_url) {
        fetch(`${backendURL}/api/cloud-shell/shalb-demo/generate?OS_IMAGE_USER=${item?.username}&VMI_IP=${item?.ip}`, {
          method: 'GET',
          headers: getHeaders(),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log('generate: ' + data);
            const svc_id = data.svc_id;
            const iframe_url = `${backendURL}/api/cloud-shell/shalb-demo/proxy?svc_id=` + svc_id;

            // 15s is enough for service to start
            setTimeout(() => {
              console.log('after setTimeout update iframe_url: ' + iframe_url, item, openedShells);
              setIframeUrl((prev) => { return iframe_url });
              window['iframeUrl'] = iframe_url;
              console.log('window  iframeUrl: ' + window['iframeUrl'])
              // window.open(iframe_url, "_blank")?.focus();


              const shells = { ...openedShells };
              shells[item!.name] = iframe_url;

              setOpenedShells(shells);
            }, 15000)

            // restart iframe with ssh every 100
            setTimeout(() => {
              console.log('delete openedShells and execute fn again ', item, openedShells);

              const shells = { ...openedShells };
              delete shells[item!.name];
              setOpenedShells(shells);
              setIframeUrl("");

              if (item) {
                setIframeAndFullscreenLink();
              }
            }, TTL)
          })
      } else {
        setIframeUrl((prev) => iframe_url);

        const shells = { ...openedShells };
        shells[item!.name] = iframe_url;

        setOpenedShells(shells);
      }



    }


    React.useEffect(() => {
      console.log('item use effect');

      if (!item) return;

      setActiveTabKey(0);
      const iframe_url = openedShells[item.name];
      if (iframe_url) {
        setIframeUrl(iframe_url);
      }


      return () => { };
    }, [item]);

    const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);
    // Toggle currently active tab
    const handleTabClick = (
      event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent,
      tabIndex: string | number
    ) => {
      console.log('tabIndex', tabIndex);
      setActiveTabKey(tabIndex);
    };

    const contentRef1 = React.createRef<HTMLElement>();
    const contentRef2 = React.createRef<HTMLElement>();

    const openInANewTab = (event) => {
      console.log("open in a new tab: ", iframeUrl);
      if (iframeUrl) {
        window.open(iframeUrl, '_blank')?.focus();
      }
    }


    const openTerminal = () => {
      console.log('openTerminal');

      if (item && item.ip !== 'Not Specified') {
        setIframeAndFullscreenLink();
        setIsLoadingSsh(true);
        const intervalId = setInterval(() => {
          setCurrentProgress((prev) => {
            console.log(prev);
            if (prev >= 100) {
              clearInterval(intervalId);
              setIsLoadingSsh(false);
              console.log(window['iframeUrl']);
              return 100;
            }
            return prev + 7
          })
        }, 1000)
      }
    }

    return (
      <React.Fragment>
        <div className='terminal-flex-row'>
          <div>
            <Tabs
              activeKey={activeTabKey}
              onSelect={handleTabClick}
              aria-label="Tabs in the seperate content example"
              role="region"
            >
              <Tab
                eventKey={0}
                title={<TabTitleText>Conditions</TabTitleText>}
                tabContentId="refTab1Section"
                tabContentRef={contentRef1}
              />
              <Tab
                eventKey={1}
                title={<TabTitleText>Terminal</TabTitleText>}
                tabContentId="refTab2Section"
                tabContentRef={contentRef2}
              />

            </Tabs>
          </div>
          <div className='terminal-flex'></div>
          <div className='close-terminal-button'>
            {activeTabKey === 1 &&
              <Button variant="plain" aria-label="Open in a new tab" onClick={openInANewTab}>
                <ExternalLinkAltIcon />
              </Button>
            }
          </div>
        </div>
        <div className="bottom-panel-tabs-content">
          <div className="inner-bottom-panel-tabs-content">
            {activeTabKey === 1 &&
              <React.Fragment>
                {!iframeUrl && !isLoadingSsh &&
                  <div onClick={openTerminal} className="launch-button-tab small-text web-console-btn">Launch SSH Terminal Here</div>

                }
                {!iframeUrl && isLoadingSsh && <div className='progress-bar-tab progress-bar-top-margin'><Progress value={currentProgress}></Progress></div>}

                {!!iframeUrl && <div id="cloud-shell-terminal" className='iframe-box'>
                  <iframe className='iframe-box' src={iframeUrl}></iframe>
                </div>}
              </React.Fragment>
            }



            {activeTabKey === 0 &&
              <div className='conditions-table'>
                <Table variant='compact'>
                  <Thead>
                    <Tr>
                      <Th>Type</Th>
                      <Th>Message</Th>
                      <Th>Status</Th>
                      <Th>Last Transition Time</Th>
                      <Th>Last Probe Time</Th>
                      <Th>Reason</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {item && item.conditions.map(function (item) {
                      return (
                        <>

                          <Tr key={item.lastTransitionTime}>
                            <Td>{item.type}</Td>
                            <Td>{item.message}</Td>
                            <Td>
                              {item.status === 'True' && <div className="green"><CheckCircleIcon></CheckCircleIcon></div>}
                              {item.status === 'False' && <div className="red"><ExclamationCircleIcon></ExclamationCircleIcon></div>}
                            </Td>
                            <Td>{item.lastTransitionTime}</Td>
                            <Td>{item.lastProbeTime}</Td>
                            <Td>{item.reason}</Td>
                          </Tr>

                        </>
                      )
                    })}
                  </Tbody>

                </Table>

              </div>
            }



          </div>
        </div>

      </React.Fragment>
    );
  };