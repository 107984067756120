import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Masthead,
  MastheadBrand,
  MastheadMain,
  Nav,
  NavExpandable,
  NavItem,
  NavList,
  Page,
  PageSidebar,
  PageSidebarBody,
  SkipToContent
} from '@patternfly/react-core';
import { IAppRoute, IAppRouteGroup, routes } from '@app/routes';
import { ProjectSelector } from './ProjectSelector';
import { SearchAll } from './SearchAll';
import { NavActionButtons } from './NavActionButtons';
import { BundleIcon } from '@patternfly/react-icons';
import Keycloak from 'keycloak-js';

type IAppLayout = {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const pageId = 'primary-app-container';

  return (
    <Page mainContainerId={pageId}>
      {children}
    </Page>
  );
};

export { AppLayout };

