import * as React from 'react';
import { Avatar, MenuToggle } from '@patternfly/react-core';
import { useContext } from 'react';
import { DataContext } from '@app/DataContext';
import { useAuth } from "react-oidc-context";
import { keycloakURL } from '@app/TreeContainer/BackendURL';

const UsernameAvatar: React.FunctionComponent = () => {
  const { givenName, setGivenName } = useContext(DataContext);
  const { familyName, setFamilyName } = useContext(DataContext);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const menuButtonRef = React.useRef<HTMLDivElement>(null);

  const auth = useAuth();

  React.useEffect(() => {
    console.log(menuButtonRef.current?.offsetWidth);
    console.log(dropdownRef.current?.style.width);
    dropdownRef.current!.style.width = menuButtonRef.current?.offsetWidth + 'px';

    document.body.addEventListener('click', function (event) {
      let node = event.target as Node;

      if (menuButtonRef.current?.contains(node)) return;
      if (dropdownRef.current?.contains(event.target as Node)) {
        // do nothing, click was inside container
      } else {
        dropdownRef.current?.classList.remove("username-show");
      }
    });

  }, [dropdownRef, givenName, familyName]);

  const toggleVisibility = () => {
    dropdownRef.current?.classList.toggle("username-show");
  }

  return (
    <>
      <div className="username-avatar">
        <div className='username-dropdown'>
          <MenuToggle ref={menuButtonRef} className='username-menu-toggle' onClick={toggleVisibility} icon={<Avatar src={`/images/img_avatar.svg`} alt="avatar" />}>{givenName} {familyName}</MenuToggle>{' '}
          <div ref={dropdownRef} className='username-dropdown-content'>
            <a onClick={() => {
              localStorage.clear();
              history.pushState("", document.title, window.location.pathname
                + window.location.search);
              void auth.removeUser();
            }} href="#">Logout</a>
          </div>
        </div>
        <div className="name"></div>
      </div>
    </>
  )
}


export { UsernameAvatar };
