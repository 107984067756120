import { DataContext } from '@app/DataContext';
import { backendURL } from '@app/TreeContainer/BackendURL';
import { getHeaders } from '@app/TreeContainer/GetHeaders';
import { showName } from '@app/TreeContainer/VirtualMachineInstance/Tabs/Monitor/MonitorTab';
import { Pod } from '@app/TreeContainer/VMParser';
import { Button, Card, CardBody, CardFooter, CardTitle, TextInputGroup, TextInputGroupMain } from '@patternfly/react-core';
import { BugIcon, CogIcon, HomeIcon, KeyIcon, MemoryIcon, MicrochipIcon, OutlinedCalendarAltIcon, OutlinedClockIcon, PowerOffIcon, VirtualMachineIcon } from '@patternfly/react-icons';
import * as React from 'react';
import { useContext } from 'react';

export interface ConfigureProps {
  item?: Pod
}

export const ConfigureView: React.FunctionComponent<ConfigureProps> = ({ item }) => {
  const [cpu, setCpu] = React.useState('');
  const [memory, setMemory] = React.useState('');
  const [terminationGracePeriodSeconds, setTerminationGracePeriodSeconds] = React.useState('');
  const [failureThreshold, setFailureThreshold] = React.useState('');
  const [initialDelay, setInitialDelay] = React.useState('');
  const [periodSeconds, setPeriodSeconds] = React.useState('');
  const [timeoutSeconds, setTimeoutSeconds] = React.useState('');
  const [machineType, setMachineType] = React.useState('');
  const [publicKeySecretName, setPublicKeySecretName] = React.useState('');
  const [guestAgentUsers, setGuestAgentUsers] = React.useState('');
  const { notifications, setNotifications } = useContext(DataContext);
  const { project, setProject } = useContext(DataContext);

  React.useEffect(() => {
    setCpu(item?.cores as string);
    setMemory(item?.memory as string);
    setTerminationGracePeriodSeconds(item?.terminationGracePeriodSeconds + '');
    setFailureThreshold(item?.failureThreshold + '');
    setInitialDelay(item?.initialDelaySeconds + '');
    setPeriodSeconds(item?.periodSeconds + '');
    setTimeoutSeconds(item?.timeoutSeconds + '');
    setMachineType(item?.instanceType + '');
    setPublicKeySecretName(item?.sshKey + '');
    setGuestAgentUsers(item?.sshUser + '');
  }, [item]);

  const updateVMConfiguration = () => {
    const performCpuUpdate = async () => {
      let headers = getHeaders();
      headers["Content-Type"] = "application/merge-patch+json";
      const response = await fetch(`${backendURL}/api/configure/${project}/cpu/${showName(item?.name)}?cpu=${cpu}`, {
        headers: headers,
        method: "PATCH",
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const resp = await response.text();

      console.log(resp);
    }

    const performMemoryUpdate = async () => {
      let headers = getHeaders();
      headers["Content-Type"] = "application/merge-patch+json";
      const response = await fetch(`${backendURL}/api/configure/${project}/memory/${showName(item?.name)}?memory=${memory}`, {
        headers: headers,
        method: "PATCH",
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const resp = await response.text();

      console.log(resp);
    }

    const performTerminationUpdate = async () => {
      let headers = getHeaders();
      headers["Content-Type"] = "application/merge-patch+json";
      const response = await fetch(`${backendURL}/api/configure/${project}/termination/${showName(item?.name)}?terminationGracePeriodSeconds=${terminationGracePeriodSeconds}`, {
        headers: headers,
        method: "PATCH",
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const resp = await response.text();

      console.log(resp);
    }

    const performReadinessUpdate = async () => {
      let headers = getHeaders();
      headers["Content-Type"] = "application/merge-patch+json";
      const response = await fetch(`${backendURL}/api/configure/${project}/readiness/${showName(item?.name)}?failureThreshold=${failureThreshold}&initialDelay=${initialDelay}&periodSeconds=${periodSeconds}&timeoutSeconds=${timeoutSeconds}`, {
        headers: headers,
        method: "PATCH",
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const resp = await response.text();

      console.log(resp);
    }

    performCpuUpdate().then(() => {
      performMemoryUpdate().then(() => {
        performTerminationUpdate().then(() => {
          performReadinessUpdate().then(() => {
            const notification = `Configuration for '${showName(item?.name)}' updated. For updates to take effect, restart the virtual machine.`;
            const notifications_local = [notification, ...notifications];
            setNotifications(notifications_local);
          })
        });
      });
    });
  }


  return (
    <React.Fragment>
      <div className='configure-container'>
        <Card ouiaId="BasicCard" className='configure-card'>
          <CardTitle>General</CardTitle>
          <CardBody>
            <MicrochipIcon></MicrochipIcon> <span className='bold-text'>Number of vCPU's</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain type='number' value={cpu} onChange={(_event, value) => setCpu(value)} />
            </TextInputGroup>
            <br />
            <MemoryIcon></MemoryIcon> <span className='bold-text'>Memory amount</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain value={memory} onChange={(_event, value) => setMemory(value)} />
            </TextInputGroup>
            <br />
            <PowerOffIcon></PowerOffIcon> <span className='bold-text'>Termination Grace Period</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain type='number' value={terminationGracePeriodSeconds} onChange={(_event, value) => setTerminationGracePeriodSeconds(value)} />
            </TextInputGroup>
            <br />
            <VirtualMachineIcon></VirtualMachineIcon> <span className='bold-text'>Machine Type</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain value={machineType} onChange={(_event, value) => setMachineType(value)} />
            </TextInputGroup>
            <br />
          </CardBody>
        </Card>

        <Card ouiaId="BasicCard" className='configure-card'>
          <CardTitle>Readiness Probe</CardTitle>
          <CardBody>
            <BugIcon></BugIcon> <span className='bold-text'>Failure Threshold</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain type='number' value={failureThreshold} onChange={(_event, value) => setFailureThreshold(value)} />
            </TextInputGroup>
            <br />
            <OutlinedCalendarAltIcon></OutlinedCalendarAltIcon> <span className='bold-text'>Initial Delay</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain type='number' value={initialDelay} onChange={(_event, value) => setInitialDelay(value)} />
            </TextInputGroup>
            <br />
            <OutlinedClockIcon></OutlinedClockIcon> <span className='bold-text'>Period Seconds</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain type='number' value={periodSeconds} onChange={(_event, value) => setPeriodSeconds(value)} />
            </TextInputGroup>
            <br />
            <CogIcon></CogIcon> <span className='bold-text'>Timeout Seconds</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain type='number' value={timeoutSeconds} onChange={(_event, value) => setTimeoutSeconds(value)} />
            </TextInputGroup>
            <br />
          </CardBody>
        </Card>

        <Card ouiaId="BasicCard" className='configure-card'>
          <CardTitle>SSH</CardTitle>
          <CardBody>
            <KeyIcon></KeyIcon> <span className='bold-text'>Public Key Secret Name</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain value={publicKeySecretName} onChange={(_event, value) => setPublicKeySecretName(value)} />
            </TextInputGroup>
            <br />
            <HomeIcon></HomeIcon> <span className='bold-text'>Guest Agent Users</span>
            <TextInputGroup className='configure-cpu-input'>
              <TextInputGroupMain value={guestAgentUsers} onChange={(_event, value) => setGuestAgentUsers(value)} />
            </TextInputGroup>
            <br />
          </CardBody>
        </Card>


      </div>

      <div className='configure-tab-container'>
        <Button onClick={updateVMConfiguration} variant="secondary" ouiaId="Secondary">
          Update
        </Button>
      </div>
    </React.Fragment>
  );
};

