import * as React from 'react';
import { Card, CardBody, CardTitle, Progress, ProgressMeasureLocation } from '@patternfly/react-core';
import { backendURL } from '@app/TreeContainer/BackendURL';
import { getHeaders } from '@app/TreeContainer/GetHeaders';
import { Pod } from '@app/TreeContainer/VMParser';
import { useContext } from 'react';
import { DataContext } from '@app/DataContext';

export interface PvcDetailsViewProps {
  pvcName?: string;
  item?: Pod,
}
export const PvcDetailsView: React.FunctionComponent<PvcDetailsViewProps> = ({ pvcName, item }) => {
  const [capacity, setCapacity] = React.useState('');
  const [claim, setClaim] = React.useState('');
  const [phase, setPhase] = React.useState('');
  const [storageClass, setStorageClass] = React.useState('');
  const [reclaimPolicy, setReclaimPolicy] = React.useState('');
  const [hostPath, setHostPath] = React.useState('');
  const [percent, setPercent] = React.useState(100);
  const [used, setUsed] = React.useState('');
  const { project, setProject } = useContext(DataContext);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!pvcName) return;

      const data = await fetch(`${backendURL}/api/volume/${project}/persistentvolumes/${pvcName}`, {
        method: 'GET',
        headers: getHeaders(),
      });
      const json = await data.json();

      console.log(json);

      if (!capacity) {
        setCapacity(json.spec.capacity.storage);
      }
      setClaim(json.spec.claimRef.name);
      setPhase(json.status.phase);
      setStorageClass(json.spec.storageClassName);
      setReclaimPolicy(json.spec.persistentVolumeReclaimPolicy);
      setHostPath(json.spec.hostPath.path);
    }

    fetchData();
  }, [pvcName]);

  React.useEffect(() => {
    if (item) {
      setPercent(parseFloat(item.metrics.storage.percent));
      setCapacity(item.metrics.storage.total + 'G');
      setUsed(item.metrics.storage.used + 'G');
    }
  }, [item]);

  return (
    <React.Fragment>
      <div className='main-card-container'>
        <Card className='pvc-card' ouiaId="BasicCard">
          <CardTitle>Data Volume</CardTitle>
          <h4 className='small-text volume-left-margin'>{claim}</h4>
          <br />
          <CardBody>
            <Progress value={percent} title="" measureLocation={ProgressMeasureLocation.inside} />
            <br />
            <div className='pvc-two-column-container'>
              <div className='column'>
                <h4 className='small-text'>Capacity</h4>
                <p className='small-text'>{capacity}</p>
                <br />
                <h4 className='small-text'>PVC</h4>
                <p className='small-text'>{pvcName}</p>
                <br />
                <h4 className='small-text'>Reclaim Policy</h4>
                <p className='small-text'>{reclaimPolicy}</p>
                <br />
              </div>
              <div className='column'>
                {!!used &&
                  <><h4 className='small-text'>Used</h4>
                    <p className='small-text'>{used}</p>
                    <br />
                  </>
                }
                <h4 className='small-text'>Storage Class</h4>
                <p className='small-text'>{storageClass}</p>
                <br />
                <h4 className='small-text'>Phase</h4>
                <p className='small-text'>{phase}</p>
                <br />
              </div>
            </div>
            <h4 className='small-text'>Host Path</h4>
            <p className='small-text'>{hostPath}</p>
            <br />
          </CardBody>
        </Card>
      </div>

    </React.Fragment>
  );
};

