import * as React from 'react';
import { Button, PageSection, Title } from '@patternfly/react-core';
import Keycloak from 'keycloak-js';
import { useHistory } from 'react-router-dom';


const Login: React.FunctionComponent = () => {
  const history = useHistory();

  const doLogin = () => {

  }

  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">Dashboard</Title>
      <Button onClick={doLogin}>Login</Button>

    </PageSection>
  )
}

export { Login };
