import * as React from 'react';
import { Button, Tooltip, TreeViewDataItem } from '@patternfly/react-core';
import { BottomPanel } from '@app/TreeContainer/VirtualMachineInstance/Tabs/Summary/BottomPanel';
import { ActionListKebab } from '@app/TreeContainer/VirtualMachineInstance/Tabs/Summary/ActionListKebab';
import { InnerTabs } from '@app/TreeContainer/VirtualMachineInstance/InnerTabs';
import ClusterIcon from '@patternfly/react-icons/dist/esm/icons/cluster-icon';
import { PauseIcon, PenIcon, PlayIcon, PlusIcon, StopIcon } from '@patternfly/react-icons';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Pod } from '../VMParser';
import { DataContext } from '@app/DataContext';
import { backendURL } from '../BackendURL';
import { getHeaders } from '../GetHeaders';


export interface VirtualMachineInstanceProps {
  activeItem: TreeViewDataItem | undefined,
  item: Pod | undefined,
  imageUrl: string | undefined,
  openedShells: any,
  setOpenedShells: Dispatch<SetStateAction<any>>,
  iframeUrl: string,
  setIframeUrl: Dispatch<SetStateAction<string>>,
  currentProgress: number,
  setCurrentProgress: Dispatch<SetStateAction<number>>,
  isLoadingSsh: boolean,
  setIsLoadingSsh: Dispatch<SetStateAction<boolean>>,
  pvcNames: string[],
}
const VirtualMachineInstance: React.FunctionComponent<VirtualMachineInstanceProps> =
  (
    {
      activeItem,
      item,
      imageUrl,
      openedShells,
      setOpenedShells,
      iframeUrl,
      setIframeUrl,
      currentProgress,
      setCurrentProgress,
      isLoadingSsh,
      setIsLoadingSsh,
      pvcNames,
    }
  ) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { refreshTree, setRefreshTree } = useContext(DataContext);
    const { notifications, setNotifications } = useContext(DataContext);
    const { project, setProject } = useContext(DataContext);
    const { showAddModal, setShowAddModal } = useContext(DataContext);



    const showName = (name) => {
      return name.replace(" ", "").replace("⏵", "").replace("⏹", "").replace("⚠", "").replace("⏸", "");
    }

    React.useEffect(() => {
      console.log('constructor?');
      setRefreshTree(refreshTree + 1);
    }, [activeItem]);

    const stopVM = async () => {
      console.log('stopVM');
      const url = backendURL + `/api/virtual-machines/${project}/stop/${item?.name}`;
      let headers = getHeaders();
      const resp = await fetch(url, {
        "method": "POST",
        headers: headers,
        "mode": "cors",
      })
      const ok = await resp.ok;
      console.log('stopVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        setTimeout(() => {
          setRefreshTree(refreshTree + 2);
        }, 10000);

        const notification = `Virtual machine '${item?.name}' successfully stopped.`;
        const notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }

    const startVM = async () => {
      console.log('startVM');
      const url = backendURL + `/api/virtual-machines/${project}/start/${showName(activeItem?.name)}`;
      let headers = getHeaders();
      const resp = await fetch(url, {
        "method": "POST",
        "mode": "cors",
        headers: headers,
      })
      const ok = await resp.ok;
      console.log('startVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        setTimeout(() => {
          setRefreshTree(refreshTree + 2);
        }, 10000);

        const notification = `Virtual machine '${showName(activeItem?.name)}' successfully started. It may take a while once it finishes loading.`;
        const notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }


    const pauseVM = async () => {
      console.log('pauseVM');
      const url = backendURL + `/api/virtual-machines/${project}/pause/${item?.name}`;
      let headers = getHeaders();
      const resp = await fetch(url, {
        "method": "POST",
        "mode": "cors",
        headers: headers,
      })
      const ok = await resp.ok;
      console.log('pauseVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        const notification = `Virtual machine '${item?.name}' successfully paused.`;
        const notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }


    const unpauseVM = async () => {
      console.log('unpauseVM');
      const url = backendURL + `/api/virtual-machines/${project}/unpause/${item?.name}`;
      let headers = getHeaders();
      const resp = await fetch(url, {
        "method": "POST",
        "mode": "cors",
        headers: headers,
      })
      const ok = await resp.ok;
      console.log('unpauseVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        const notification = `Virtual machine '${item?.name}' successfully resumed.`;
        const notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }

    return (
      <React.Fragment>

        <div className='fullpage-content-top-section'>
          <div className='top-section-content'>
            <div className="large-icon"><ClusterIcon /></div>
            <span className="header">{(activeItem && showName(activeItem.name)) || 'Not Selected'}</span>
            {item?.printableStatus === 'Stopped' &&
              <Tooltip content={'Start Virtual Machine'}>
                <Button variant="plain" aria-label="Start">
                  <PlayIcon onClick={startVM} />
                </Button>
              </Tooltip>
            }

            {item?.printableStatus === 'Running' &&
              <React.Fragment>
                <Tooltip content={'Pause Virtual Machine'}>
                  <Button onClick={pauseVM} variant="plain" aria-label="Pause">
                    <PauseIcon />
                  </Button>
                </Tooltip>
                <Tooltip content={'Stop Virtual Machine'}>
                  <Button onClick={stopVM} variant="plain" aria-label="Stop">
                    <StopIcon />
                  </Button>
                </Tooltip>
              </React.Fragment>
            }

            {item?.printableStatus === 'Paused' &&
              <Tooltip content={'Resume Virtual Machine'}>
                <Button onClick={unpauseVM} variant="plain" aria-label="Unpause">
                  <PlayIcon />
                </Button>
              </Tooltip>
            }

            <Tooltip content={'Add New Virtual Machine'}>
              <Button onClick={() => {
                setShowAddModal(showAddModal + 1);
              }} variant="plain" aria-label="Action">
                <PlusIcon />
              </Button>
            </Tooltip>

            <ActionListKebab pod={item}></ActionListKebab>
          </div>
          <div className="inner-tabs">
            <InnerTabs
              item={item}
              imageUrl={imageUrl}
              openedShells={openedShells}
              setOpenedShells={setOpenedShells}
              iframeUrl={iframeUrl}
              setIframeUrl={setIframeUrl}
              currentProgress={currentProgress}
              setCurrentProgress={setCurrentProgress}
              isLoadingSsh={isLoadingSsh}
              setIsLoadingSsh={setIsLoadingSsh}
              pvcNames={pvcNames}
              activeItem={activeItem}></InnerTabs>
          </div>

        </div>
        <div className='fullpage-content-bottom-section ' >
          <div className="fullpage-content-nested-bottom-section">
            <BottomPanel item={item}
              openedShells={openedShells}
              setOpenedShells={setOpenedShells}
              iframeUrl={iframeUrl}
              currentProgress={currentProgress}
              setCurrentProgress={setCurrentProgress}
              isLoadingSsh={isLoadingSsh}
              setIsLoadingSsh={setIsLoadingSsh}
              setIframeUrl={setIframeUrl}></BottomPanel>
          </div>
        </div>



      </React.Fragment>

    )
  }

export { VirtualMachineInstance };
